<template>
  <div class="row">
    <div class="card p-lg-10 p-sm-5">
      <h2 class="text-blue">Editar ubicación</h2>
      <div class="mt-5">
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <div class="p-2 my-5">
              <label class="text-secondary" for="">País</label>
              <select
                class="form-select btn bg-gray-input w-100 text-start d-flex align-items-center rounded-0"
                v-model="paisId"
                id="paisId"
                name="paisId"
              >
                <option disabled value="">País</option>
                <option v-for="(pais, i) in paises" :key="i" :value="pais._id">
                  {{ pais.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="p-2 my-5">
              <label class="text-secondary" for="">Departamento</label>
              <select
                class="form-select btn bg-gray-input w-100 text-start d-flex align-items-center rounded-0"
                v-model="departamentoId"
                id="departamentoId"
                name="departamentoId"
              >
                <option disabled value="">Departamento</option>
                <option
                  v-for="(departamento, e) in departamentos"
                  :key="e"
                  :value="departamento.id"
                >
                  {{ departamento.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-12">
            <div class="p-2 my-5">
              <label class="text-secondary" for="">Ciudad</label>
              <select
                class="form-select btn bg-gray-input w-100 text-start d-flex align-items-center rounded-0"
                v-model="ciudadId"
              >
                <option>Ciudad</option>
                <option
                  v-for="(ciudad, j) in ciudades"
                  :key="j"
                  :value="ciudad.id"
                >
                  {{ ciudad.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-12">
            <div class="p-2 my-5">
              <label class="text-secondary" for="">Dirección</label>
              <div class="dropdown w-100">
                <input
                  type="text"
                  class="form-control rounded-0 bg-gray-input border-0"
                  v-model="model.direccion"
                />
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="p-2 my-5">
              <label class="text-secondary" for=""
                >Nombre de la ubicación</label
              >
              <div class="dropdown w-100">
                <input
                  type="text"
                  class="form-control rounded-0 bg-gray-input border-0"
                  v-model="model.nombre"
                />
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="text-center my-5">
              <div style="">
                <div>
                  <button class="btn bg-blue text-white">
                    Guardar cambios
                  </button>
                </div>
                <div>
                  <button class="btn text-blue">Cancelar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, watch } from "vue";
import sedes from "@/services/sedes.service";
import { useRoute } from "vue-router";
import { ErrorMessage, useField, useForm } from "vee-validate";
import * as Yup from "yup";

export default defineComponent({
  name: "editar-sedes",
  setup() {
    const route = useRoute();
    const infoSedes = ref({});
    const paises = ref([]);
    const paisId = ref("");
    const departamentos = ref([]);
    const departamentoId = ref("");
    const ciudades = ref([]);
    const ciudadId = ref("");
    const verDeLasSedes = () => {
      sedes.verSedePorId(route.params.id).then((res) => {
        console.log("info sede", res.data);
        infoSedes.value = res.data;
        model.nombre = res.data.nombre;
        model.direccion = res.data.direccion;
        ciudadId.value = res.data.ciudadId;
      });
    };
    const validationSchema = Yup.object().shape({
      nombre: Yup.string().required("Este campo es requerido"),
      direccion: Yup.string().required("Este campo es requerido"),
    });
    const { handleSubmit, values: model } = useForm({
      validationSchema,
    });
    const editarSede = handleSubmit((values) => {
      sedes.editarSede(route.params.id, values).then((res) => {
        console.log(res);
      });
    });
    useField("nombre", null, { initialValue: "" });
    const traerPaises = () => {
      sedes.listarPaises().then((res) => {
        console.log(res);
        paises.value = res.data;
      });
    };
    const traerDepartamentos = () => {
      sedes.listarDepartamentos(paisId.value).then((res) => {
        console.log(res);
        departamentos.value = res.data;
      });
    };
    const traerCiudades = () => {
      sedes.listarCiudades(departamentoId.value).then((res) => {
        console.log(res);
        ciudades.value = res.data;
      });
    };
    watch(
      () => paisId.value,
      () => {
        traerDepartamentos();
      }
    );
    watch(
      () => departamentoId.value,
      () => {
        traerCiudades();
      }
    );
    onMounted(() => {
      traerPaises();
      verDeLasSedes(route.params.id);
    });
    return {
      paises,
      departamentos,
      paisId,
      departamentoId,
      ciudades,
      infoSedes,
      model,
      editarSede,
      ciudadId,
    };
  },
});
</script>
<style scoped>
.border-blue {
  border-color: #27aae1 !important;
}
.text-gray {
  color: #999999;
}
.bg-blue {
  background-color: #27aae1;
}
.bg-gray-input {
  background-color: #f4f8fb;
}
</style>
